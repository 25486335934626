import { Button as Button2, Popup } from "devextreme-react";
import DataGrid, { Button, Column, Toolbar, Item, DataGridRef } from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataList } from "../../components/data-list/DataList";
import { FullSpinner } from "../../components/spinner/Spinner";
import { EDITOR_URI, FILL_URI, LIST_URI, RESULTS_URI } from "../../constants";
import { useMsalToken } from "../../hooks/useToken";
import { Company } from "../../models/company";
import { Employee } from "../../models/employee";
import { Eval } from "../../models/evaluation";
import { Team } from "../../models/team";
import { GetAverageScoreForEvalAsync, GetCompaniesAsync, GetCompanyTeamsAsync, GetEvaluationsForTeamAsync, GetEvaluationWithUidAsync, GetTeamWithIdAsync, InsertOrUpdateEvalAsync } from "../../services/http-service";
import './EvalList.scss';

export const EvalList = () => {
    const token = useMsalToken();
    const navigate = useNavigate();

    const [evals, setEvals] = useState<Eval[]>();
    const [evalScores, setEvalScores] = useState(new Map<string, number>());
    const [companies, setCompanies] = useState<Company[]>();
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState<number>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>();

    const [teams, setTeams] = useState<Team[]>();
    const [selectedTeamIndex, setSelectedTeamIndex] = useState<number>();

    const [teamDetails, setTeamDetails] = useState<Team>();

    const [fillEval, setFillEval] = useState<{ uid: string, version: number }>();
    const [fillEvalCopy, setFillEvalCopy] = useState<{ uid: string, version: number }>();

    const [selectedTeamToCopyTo, setSelectedTeamToCopyTo] = useState<Team>();

    const [isLoading, setIsLoading] = useState(false);
    const employeesGrid = createRef<DataGridRef<Employee, any>>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!companies) return;

        let params = new URLSearchParams(window.location.search);
        let compId = params.get('company');
        let teamId = params.get('team');

        if (compId !== null) {
            const companyIndex = Number.parseInt(compId);
            setSelectedCompanyId(companies[companyIndex].id);
            setSelectedCompanyIndex(companyIndex);
        }
        if (teamId !== null) {
            setSelectedTeamIndex(Number.parseInt(teamId));
        }
    });

    useEffect(() => {
        if (!token) return;
        setIsLoading(true);
        GetCompaniesAsync(token)
            .then(d => {
                if (d.ok) {
                    setCompanies(d.data);
                    if (d.data?.length === 1) {
                        setSelectedCompanyIndex(0);
                    }
                } else {
                    notify(`There was an error: ${d.error}`, 'error', 2300);
                }
                setIsLoading(false);
            });
    }, [token]);

    useEffect(() => {
        if (!companies || selectedCompanyIndex === undefined || !token) return;
        setSelectedTeamIndex(undefined);
        setIsLoading(true);
        GetCompanyTeamsAsync(companies[selectedCompanyIndex]?.id, token)
            .then(d => {
                if (d.ok) {
                    setTeams(d.data)
                } else {
                    notify(`There was an error: ${d.error}`, 'error', 2300);
                }
                setIsLoading(false);
            });
    }, [companies, selectedCompanyIndex, token]);

    useEffect(() => {
        if (!teams || selectedTeamIndex === undefined || !token) return;
        setIsLoading(true);
        GetEvaluationsForTeamAsync(token, teams[selectedTeamIndex].id!)
            .then(d => {
                if (d.ok) {
                    setEvals(d.data)
                    if (d.data) {
                        d.data.forEach(e => GetAverageScoreForEvalAsync(e.uid!, token).then(r => {
                            if (r.data) {
                                evalScores.set(e.uid!, r.data.avgScore);
                                setEvalScores(new Map(evalScores));
                            }
                        }))
                    }
                } else {
                    notify(`There was an error: ${d.error
                        }`, 'error', 2300);
                }
            });
        GetTeamWithIdAsync(teams[selectedTeamIndex].id!, token)
            .then((d) => {
                if (d.ok) {
                    setTeamDetails(d.data);
                } else {
                    notify(`There was an error while getting the team's details: ${d.error?.message}`, "error", 2300);
                }
                setIsLoading(false);
            });
    }, [teams, selectedTeamIndex, token]);

    const copyEvalToTeam = () => {
        if (!token || !fillEvalCopy) return;
        setIsLoading(true);
        GetEvaluationWithUidAsync(token, fillEvalCopy.uid, fillEvalCopy.version)
            .then(d => {
                if (d.ok && d.data) {
                    d.data.uid = undefined;
                    d.data.version = 1;
                    d.data.teamId = selectedTeamToCopyTo?.id;
                    d.data.evaluationQuestionSections.forEach((s) => { s.uid = undefined; s.version = 1; s.evaluationQuestions.forEach((q) => { q.uid = undefined; q.version = 1; }) })
                    InsertOrUpdateEvalAsync(token, d.data, true)
                        .then((d) => {
                            if (d.ok) {
                                notify(`The evaluation was copied successfully`, "success");
                            } else {
                                notify(`There was an error: ${d.error?.message}`, "error");
                            }
                            setFillEvalCopy(undefined);
                            setSelectedTeamToCopyTo(undefined);

                        });
                } else {
                    notify(`There was an error: ${d.error?.message}`, "error");
                }
                setIsLoading(false);
            });
    };

    return (
        <div className="m-2">
            {isLoading && <FullSpinner />}
            <div className="container" style={{ maxWidth: 'none' }}>
                <div className="row">
                    <div className="col-2">
                        <DataList
                            selectionIndex={selectedCompanyIndex}
                            data={companies}
                            display={(c) => c?.companyName}
                            onSelected={(c, i) => { if (i === selectedCompanyIndex) return; navigate(`${LIST_URI}?company=${i}`); setEvals(undefined); setTeams(undefined); }} />
                    </div>
                    {selectedCompanyIndex !== undefined &&
                        <div className="col-2">
                            <DataList
                                searchBar={{ show: true, filter: (d, st) => { return st ? d.filter(t => t.name.includes(st)) : d } }}
                                selectionIndex={selectedTeamIndex}
                                data={teams}
                                display={(t) => t.name}
                                onSelected={(_, i) => { if (i === selectedTeamIndex) return; navigate(`${LIST_URI}?company=${selectedCompanyIndex}&team=${i}`); setEvals(undefined); }} />
                        </div>
                    }
                    {evals &&
                        <div className="col">
                            <DataGrid
                                keyExpr="name"
                                dataSource={evals}
                                showBorders={true}>
                                <Toolbar>
                                    <Item location="before">
                                        <Button2 icon="add" text="Create an evaluation" onClick={() => navigate(`${EDITOR_URI}?team=${teams![selectedTeamIndex!].id}`)} />
                                    </Item>
                                    <Item location="before">
                                        <Button2 icon="doc" text="View Results" onClick={() => navigate(`${RESULTS_URI}?mode=company&id=${selectedCompanyId}`)} />
                                    </Item>
                                </Toolbar>
                                <Column type="buttons" width='100px'>
                                    <Button
                                        icon="edit"
                                        hint="Edit the evaluation"
                                        onClick={(e: any) => navigate(`${EDITOR_URI}?eval=${e.row.data.uid}&version=${e.row.data.version}&team=${e.row.data.teamId}`)} />
                                    <Button
                                        icon="textdocument"
                                        hint="Fill the evaluation"
                                        onClick={(e: any) => setFillEval({ uid: e.row.data.uid, version: e.row.data.version })} />
                                    <Button
                                        icon="copy"
                                        hint="Copy the evaluation"
                                        onClick={(e: any) => setFillEvalCopy({ uid: e.row.data.uid, version: e.row.data.version })} />
                                </Column>
                                <Column dataField="name" />
                                <Column dataField="version" />
                                <Column caption="Average Score" calculateCellValue={(e: Eval) => {
                                    const val = evalScores.get(e.uid!);
                                    return val !== undefined ? `${(val * 100).toFixed(2)} / 100` : 'N/A';
                                }} />
                            </DataGrid>
                        </div>
                    }
                </div>
                <Popup
                    visible={fillEval !== undefined}
                    onHiding={() => setFillEval(undefined)}
                    hideOnOutsideClick={true}
                    title="Select an employee...">
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={{ text: "Submit", onClick: () => navigate(`${FILL_URI}?eval=${fillEval?.uid}&version=${fillEval?.version}&employee=${employeesGrid.current?.instance().getSelectedRowsData()[0].id}`) }}
                    />
                    <DataGrid
                        ref={employeesGrid}
                        dataSource={teamDetails?.employees}
                        selection={{ mode: 'single' }}
                        showBorders={true}
                        searchPanel={{ visible: true }}
                        height="80%">
                        <Column dataField="employeeId" />
                        <Column dataField="firstName" />
                        <Column dataField="lastName" />
                    </DataGrid>
                </Popup>
                <Popup
                    visible={fillEvalCopy !== undefined}
                    onHiding={() => setFillEvalCopy(undefined)}
                    hideOnOutsideClick={true}
                    title="Select a team...">
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={{ text: "Submit", onClick: () => copyEvalToTeam() }} />
                    <DataGrid showBorders={true} dataSource={teams} selection={{ mode: 'single' }} onSelectionChanged={(e) => { setSelectedTeamToCopyTo(e.component.getSelectedRowsData()[0]) }}>
                        <Column dataField="id" />
                        <Column dataField="name" />
                    </DataGrid>
                </Popup>
            </div>
        </div >
    )
};
